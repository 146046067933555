<template>
    <div>
        <el-radio-group :value="value" @input="$emit('input',$event)" v-if="options.length>0">
            <el-radio v-for="item in options" :key="item" :label="item">{{item}}</el-radio>
        </el-radio-group>
        <div v-else>请选择院区</div>
    </div>
</template>
<script>
    export default {
        name: "radioDate",
        props: {
            value: {
                type: [String, Number,]
            },
            hospital_id: {
                required: true
            },
            department_id: {
                required: true
            },
            doctor_id: {
                required: true
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            hospital_id(newVal) {
                this.getData()
            },
            department_id(){
                this.getData()
            },
            doctor_id(){
                this.getData()
            }
        },
        data() {
            return {
                options: []
            }
        },
        methods: {
            async getData() {
                if (this.hospital_id && this.department_id && this.doctor_id) {
                    const resp = await this.$http.get(`/mingde/worksheet/getDates?hospital_id=${this.hospital_id}&department_id=${this.department_id}&doctor_id=${this.doctor_id}`)
                    console.log(resp)
                    this.options = resp.data.data || []
                }
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
