<template>
    <el-checkbox-group :value="value" @input="$emit('input', $event)">
        <el-checkbox :label="w.value" v-for="(w, i) in options" :key="i">{{w.name}}</el-checkbox>
    </el-checkbox-group>
</template>
<script>
    export default {
        name: "checkbox-weekday",
        props: {
            value: {
                default: true
            }
        },
        data() {
            return {
                options:[
                    {name: '周日', value: 0},
                    {name: '周一', value: 1},
                    {name: '周二', value: 2},
                    {name: '周三', value: 3},
                    {name: '周四', value: 4},
                    {name: '周五', value: 5},
                    {name: '周六', value: 6},
                ]
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
