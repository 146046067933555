<template>
    <el-radio-group :value="value" @input="$emit('input',$event)">
        <el-radio v-for="item in options" :key="item.id" :label="item.id">{{item.name}}</el-radio>
    </el-radio-group>
</template>
<script>
    export default {
        name: "radioHospital",
        props: {
            value: {
                type: [String, Number,]
            },
            size: {
                default: 'small'
            }
        },
        data() {
            return {
                options:[]
            }
        },
        methods: {
            async getData() {
                const hl = await this.$http.get('/mingde/hospital/')
                this.options = hl.data.data || []
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
