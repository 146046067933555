<template>
    <div>
        <el-radio-group :value="value" @input="$emit('input',$event)" v-if="options.length>0">
            <el-radio v-for="item in options" :key="item.id" :label="item.id">{{item.name}}</el-radio>
        </el-radio-group>
        <div v-else>请选择院区</div>
    </div>
</template>
<script>
    export default {
        name: "radioDoctor",
        props: {
            value: {
                type: [String, Number,]
            },
            hospital_id: {
                required: true
            },
            type_id: {
                required: true
            },
            department_id: {
                required: true
            },
            size: {
                default: 'small'
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            hospital_id(newVal) {
                this.getData()
            },
            type_id() {
                this.getData()
            },
            department_id() {
                this.getData()
            }
        },
        data() {
            return {
                options: []
            }
        },
        methods: {
            async getData() {
                if (this.hospital_id && this.type_id && this.department_id) {
                    const hl = await this.$http.get(`/mingde/doctor/?hospital_id=${this.hospital_id}&type_id=${this.type_id}&department_id=${this.department_id}`)
                    this.options = hl.data.data.data || []
                }
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
