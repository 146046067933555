<template>
    <el-select :value="value" @input="$emit('input',$event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>
<script>
    export default {
        name: "selectType",
        props: {
            value: {
                type: [String, Number,]
            },
            hospital_id: {
                required: true
            },
            size: {
                default: 'small'
            }
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            hospital_id(newVal){
                this.getData()
            }
        },
        data() {
            return {
                options:[]
            }
        },
        methods: {
            async getData() {
                const hl = await this.$http.get(`/mingde/type/?hospital_id=${this.hospital_id}`)
                this.options = hl.data.data.data
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
